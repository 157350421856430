import AdminGatewayServerConnector from 'services/connectors/AdminGatewayServerConnector';

class AuthService extends AdminGatewayServerConnector {
  constructor() {
    super('/auth');
  }

  signIn(data) {
    return this.post('/login', data);
  }

  getAccount() {
    return this.get('/account-details');
  }

  logout() {
    return this.post('/logout');
  }
}

export default new AuthService();
