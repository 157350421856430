import * as types from 'store/types';

const initialState = {
  subscriptionsList: [],
  errors: [],
  isRequesting: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.SUBSCRIPTIONS_GET_REQUEST: {
      return {
        ...state,
        isRequesting: action.isRequesting || false,
      };
    }
    case types.SUBSCRIPTIONS_GET_SUCCESS: {
      return {
        ...state,
        isRequesting: false,
        subscriptionsList: action.subscriptionsList,
        errors: [],
      };
    }
    case types.SUBSCRIPTIONS_GET_FAILURE: {
      return {
        ...state,
        isRequesting: false,
        subscriptionsList: [],
        errors: action.errors,
      };
    }

    case types.SUBSCRIPTION_STORE_RESET: {
      return {
        ...state,
        subscriptionsList: [],
        errors: [],
        isRequesting: false,
      };
    }

    case types.SUBSCRIPTION_DELETE_REQUEST: {
      return {
        ...state,
        isRequesting: action.isRequesting,
      };
    }

    case types.SEND_CREDITS_REQUEST: {
      return {
        ...state,
        isRequesting: action.isRequesting,
      };
    }
    case types.SEND_CREDITS_SUCCESS: {
      return {
        ...state,
        isRequesting: false
      };
    }
    default:
      return state;
  }
}
