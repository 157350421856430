import * as types from 'store/types';

const initialState = {
  errors: [],
  isRequesting: false,
  account: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.SIGN_IN_REQUEST: {
      return {
        ...state,
        isRequesting: true,
      };
    }
    case types.SIGN_IN_SUCCESS: {
      return {
        ...state,
        isRequesting: false,
        errors: [],
      };
    }
    case types.SIGN_IN_FAILURE: {
      return {
        ...state,
        isRequesting: false,
        errors: action.errors,
      };
    }

    case types.SET_ACCOUNT_DATA: {
      return {
        ...state,
        account: action.account,
      };
    }

    case types.LOG_OUT_REQUEST: {
      return {
        ...state,
        isRequesting: true,
      };
    }
    case types.LOG_OUT_SUCCESS: {
      return {
        ...state,
        isRequesting: false,
        account: {},
      };
    }

    case types.RESET_AUTH_ERRORS: {
      return {
        ...state,
        errors: [],
      };
    }

    case types.RESET_AUTH_STORE: {
      return {
        errors: [],
        isRequesting: false,
        account: {},
      };
    }

    default:
      return state;
  }
}
