import authHelper from 'helpers/authHelper';

import * as types from 'store/types';
import generalActions from 'store/general/generalActions';

import AuthService from 'services/AuthService';

export default {
  initAuthFlow,
  signIn,
  logOut,
  resetAuthErrors,
};

function initAuthFlow() {
  return async (dispatch) => {
    try {
      if (authHelper.isRegistered()) {
        await dispatch(getAuthAccount());
      }
      return true;
    } catch (e) {
      dispatch(logOut());
      return false;
    }
  };
}

function signIn(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: types.SIGN_IN_REQUEST,
    });

    try {
      const dataToSend = {
        ...data,
        email: data.email?.toLowerCase(),
      };

      const { success, errors, headers } = await AuthService.signIn(dataToSend);

      if (success) {
        dispatch(generalActions.setGlobalLoading(true));
        authHelper.setToken(headers['x-auth-token']);

        await dispatch(getAuthAccount());

        dispatch({
          type: types.SIGN_IN_SUCCESS,
        });

        return true;
      } else {
        throw errors;
      }
    } catch (e) {
      if (e === 'REQUIRE_LOGOUT') {
        dispatch(logOut());
      } else {
        dispatch({
          type: types.SIGN_IN_FAILURE,
          errors: Array.isArray(e) ? e : [],
        });
      }
      throw e;
    } finally {
      dispatch(generalActions.setGlobalLoading(false));
    }
  };
}

function getAuthAccount() {
  return async (dispatch) => {
    try {
      const { success, response } = await AuthService.getAccount();

      if (success) {
        dispatch({
          type: types.SET_ACCOUNT_DATA,
          account: response,
        });
        return true;
      } else {
        throw 'REQUIRE_LOGOUT';
      }
    } catch (e) {
      throw 'REQUIRE_LOGOUT';
    }
  };
}

function logOut() {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.LOG_OUT_REQUEST,
      });

      await AuthService.logout();
    } finally {
      authHelper.removeToken();
      dispatch({
        type: types.LOG_OUT_SUCCESS,
      });
      dispatch(resetStore());
    }
  };
}

function resetAuthErrors() {
  return {
    type: types.RESET_AUTH_ERRORS,
  };
}

function resetStore() {
  return {
    type: types.RESET_AUTH_STORE,
  };
}
