import { API_ENDPOINT } from 'configs/configs';

const isLocal = () => {
  return (
    window.location.hostname === 'localhost' ||
    window.location.hostname.includes('.local')
  );
};

const apiEndpoint = () => {
  let host = window.location.origin;

  if (isLocal()) {
    host = API_ENDPOINT;
  }
  return host;
};

const getHostWithoutProtocol = () => {
  return 'platform.vntana.com';
};

const asset = (file) => {
  return `/assets/${file}`;
};

export { isLocal, getHostWithoutProtocol, asset };
export default apiEndpoint;
