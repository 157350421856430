import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import authReducer from './auth/authReducer';
import generalReducer from './general/generalReducer';
import messagesReducer from './messages/messagesReducer';
import organizationReducer from './organization/organizationReducer';
import subscriptionReducer from './subscription/subscriptionReducer';
import userReducer from './user/userReducer';

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    general: generalReducer,
    auth: authReducer,
    messages: messagesReducer,
    organization: organizationReducer,
    subscription: subscriptionReducer,
    user: userReducer,
  });
export default createRootReducer;
