import AdminGatewayServerConnector from './connectors/AdminGatewayServerConnector';
import Countries from 'configs/countries';

class CountryService extends AdminGatewayServerConnector {
  constructor() {
    super('/countries');
  }

  getAll() {
    return Promise.resolve({
      errors: {},
      success: true,
      response: Countries,
    });
  }
}

export default new CountryService();
