//General
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_TRANSLATIONS = 'SET_TRANSLATIONS';
export const SET_GLOBAL_LOADING = 'SET_GLOBAL_LOADING';
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';

//messages
export const MESSAGE_REMOVE = 'MESSAGE_REMOVE';
export const MESSAGE_REMOVE_BY_KEY = 'MESSAGE_REMOVE_BY_KEY';
export const MESSAGE_ADD = 'MESSAGE_ADD';

//Auth
export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';

export const SET_ACCOUNT_DATA = 'SET_ACCOUNT_DATA';

export const LOG_OUT_REQUEST = 'LOG_OUT_REQUEST';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';

export const RESET_AUTH_ERRORS = 'RESET_AUTH_ERRORS';
export const RESET_AUTH_STORE = 'RESET_AUTH_STORE';

//Organization
export const ORGANIZATION_LIST_GET_REQUEST = 'ORGANIZATION_LIST_GET_REQUEST';
export const ORGANIZATION_LIST_GET_SUCCESS = 'ORGANIZATION_LIST_GET_SUCCESS';
export const ORGANIZATION_LIST_GET_FAILURE = 'ORGANIZATION_LIST_GET_FAILURE';

export const ORGANIZATION_ITEM_GET_REQUEST = 'ORGANIZATION_ITEM_GET_REQUEST';
export const ORGANIZATION_ITEM_SET = 'ORGANIZATION_ITEM_SET';

export const ORGANIZATION_ITEM_SAVE_REQUEST = 'ORGANIZATION_ITEM_SAVE_REQUEST';
export const ORGANIZATION_ITEM_SAVE_FAILURE = 'ORGANIZATION_ITEM_SAVE_FAILURE';

export const ORGANIZATION_SUBSCRIPTION_GET_REQUEST =
  'ORGANIZATION_SUBSCRIPTION_GET_REQUEST';
export const ORGANIZATION_CURRENT_SUBSCRIPTION_SET =
  'ORGANIZATION_CURRENT_SUBSCRIPTION_SET';
export const ORGANIZATION_SUBSCRIPTION_CUSTOMER_INFO =
  'ORGANIZATION_SUBSCRIPTION_CUSTOMER_INFO';
export const ORGANIZATION_CHANGE_SUBSCRIPTION_MODAL_TOGGLE =
  'ORGANIZATION_CHANGE_SUBSCRIPTION_MODAL_TOGGLE';
export const ORGANIZATION_SUBSCRIPTION_UPDATE_REQUEST =
  'ORGANIZATION_SUBSCRIPTION_UPDATE_REQUEST';

export const ORGANIZATION_STORE_RESET = 'ORGANIZATION_STORE_RESET';

//Users
export const USER_LIST_GET_REQUEST = 'USER_LIST_GET_REQUEST';
export const USER_LIST_GET_SUCCESS = 'USER_LIST_GET_SUCCESS';
export const USER_LIST_GET_FAILURE = 'USER_LIST_GET_FAILURE';

export const USER_ITEM_SAVE_REQUEST = 'USER_ITEM_SAVE_REQUEST';
export const USER_ITEM_SAVE_FAILURE = 'USER_ITEM_SAVE_FAILURE';

export const USER_STORE_RESET = 'USER_STORE_RESET';

//Subscriptions
export const SUBSCRIPTIONS_GET_REQUEST = 'SUBSCRIPTIONS_GET_REQUEST';
export const SUBSCRIPTIONS_GET_SUCCESS = 'SUBSCRIPTIONS_GET_SUCCESS';
export const SUBSCRIPTIONS_GET_FAILURE = 'SUBSCRIPTIONS_GET_FAILURE';
export const SUBSCRIPTION_STORE_RESET = 'SUBSCRIPTION_STORE_RESET';
export const SUBSCRIPTION_DELETE_REQUEST = 'SUBSCRIPTION_DELETE_REQUEST';
export const SEND_CREDITS_REQUEST = 'SEND_CREDITS_REQUEST';
export const SEND_CREDITS_SUCCESS = 'SEND_CREDITS_SUCCESS';
