import * as Yup from 'yup';

const decimalNumber = () => /^[-]?\d+(\.\d+)?$/;
const positiveNumber = () => /^\d/;
const decimalNumberWithTwoDecimalPlaces = () => /^[-]?\d+(\.\d{0,2})?$/;

const passwordValidator = () => /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;

const ipAddressValidator = () =>
  /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/;

const slugValidator = () => /^[A-Za-z0-9._~-]+$/;

const slugNotAllowedCharactersValidator = () =>
  /[!*'();:@&=+$,\/?%#\[\]"<>\|]*/g;

const phoneNumberValidator = () => /^(\+([0-9]{1,4}))?([-.()\s]*[0-9])*$/;

export const addYupCustomValidators = () => {
  Yup.addMethod(Yup.array, 'unique', function (message, path) {
    return this.test('unique', message, function (list) {
      if (!list || list.length === 0) return true;

      const pathList = list.map((item) => item[path]);
      const duplicates = pathList.getDuplicates();

      if (Object.keys(duplicates).length === 0) {
        return true;
      }

      const errors = [];
      for (let key in duplicates) {
        if (duplicates.hasOwnProperty(key) && key.trim().length > 0) {
          duplicates[key].forEach((itemIndex, index) => {
            if (index > 0) {
              errors.push(
                this.createError({
                  path: `${this.path}[${itemIndex}].${path}`,
                  message,
                })
              );
            }
          });
        }
      }

      if (errors.length) {
        throw this.createError({
          path: `${this.path}`,
          message: errors,
        });
      } else {
        return true;
      }
    });
  });
};

export default {
  decimalNumber,
  positiveNumber,
  decimalNumberWithTwoDecimalPlaces,
  passwordValidator,
  ipAddressValidator,
  slugValidator,
  slugNotAllowedCharactersValidator,
  phoneNumberValidator,
};
