import * as types from 'store/types';

const initialState = {
  isRequesting: false,
  organizationList: [],
  totalCount: 0,

  organization: {},
  isOrganizationRequesting: false,
  isOrganizationSaving: false,

  currentSubscription: {},
  customerInfo: {},
  isChangeSubscriptionModalOpen: false,
  isSubscriptionUpdating: false,

  errors: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.ORGANIZATION_LIST_GET_REQUEST: {
      return {
        ...state,
        isRequesting: true,
      };
    }
    case types.ORGANIZATION_LIST_GET_SUCCESS: {
      return {
        ...state,
        isRequesting: false,
        organizationList: action.organizationList,
        totalCount: action.totalCount,
      };
    }
    case types.ORGANIZATION_LIST_GET_FAILURE: {
      return {
        ...state,
        isRequesting: false,
        organizationList: [],
        totalCount: 0,
        errors: action.errors,
      };
    }

    case types.ORGANIZATION_ITEM_GET_REQUEST: {
      return {
        ...state,
        isOrganizationRequesting: action.isOrganizationRequesting,
      };
    }
    case types.ORGANIZATION_ITEM_SET: {
      return {
        ...state,
        organization: action.organization,
      };
    }
    case types.ORGANIZATION_ITEM_SAVE_REQUEST: {
      return {
        ...state,
        isOrganizationSaving: action.isOrganizationSaving,
      };
    }
    case types.ORGANIZATION_ITEM_SAVE_FAILURE: {
      return {
        ...state,
        errors: action.errors,
      };
    }

    case types.ORGANIZATION_SUBSCRIPTION_GET_REQUEST: {
      return {
        ...state,
        isSubscriptionRequesting: action.isSubscriptionRequesting,
      };
    }
    case types.ORGANIZATION_CURRENT_SUBSCRIPTION_SET: {
      return {
        ...state,
        currentSubscription: action.currentSubscription,
      };
    }
    case types.ORGANIZATION_SUBSCRIPTION_CUSTOMER_INFO: {
      return {
        ...state,
        customerInfo: action.customerInfo,
      };
    }
    case types.ORGANIZATION_CHANGE_SUBSCRIPTION_MODAL_TOGGLE: {
      return {
        ...state,
        isChangeSubscriptionModalOpen: action.isChangeSubscriptionModalOpen,
      };
    }
    case types.ORGANIZATION_SUBSCRIPTION_UPDATE_REQUEST: {
      return {
        ...state,
        isSubscriptionUpdating: action.isSubscriptionUpdating,
      };
    }

    case types.ORGANIZATION_STORE_RESET: {
      return {
        ...state,
        isRequesting: false,
        organizationList: [],
        totalCount: 0,

        organization: {},
        isOrganizationRequesting: false,
        isOrganizationSaving: false,

        currentSubscription: {},
        isChangeSubscriptionModalOpen: false,
        isSubscriptionUpdating: false,

        errors: [],
      };
    }

    default:
      return state;
  }
}
