import * as types from 'store/types';

const initialState = {
  isRequesting: false,
  userList: [],
  totalCount: 0,
  isUserSaving: false,
  errors: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.USER_LIST_GET_REQUEST: {
      return {
        ...state,
        isRequesting: action.isRequesting,
      };
    }
    case types.USER_LIST_GET_SUCCESS: {
      return {
        ...state,
        userList: action.userList,
        totalCount: action.totalCount,
        errors: [],
      };
    }
    case types.USER_LIST_GET_FAILURE: {
      return {
        ...state,
        userList: [],
        totalCount: 0,
      };
    }

    case types.USER_ITEM_SAVE_REQUEST: {
      return {
        ...state,
        isUserSaving: action.isUserSaving,
      };
    }
    case types.USER_ITEM_SAVE_FAILURE: {
      return {
        ...state,
        errors: action.errors,
      };
    }

    case types.USER_STORE_RESET: {
      return {
        ...state,
        isRequesting: false,
        userList: [],
        totalCount: 0,

        isUserSaving: false,

        errors: [],
      };
    }

    default:
      return state;
  }
}
