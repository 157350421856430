import AdminGatewayServerConnector from './connectors/AdminGatewayServerConnector';
import Translations from 'configs/translation';

class TranslationService extends AdminGatewayServerConnector {
  constructor() {
    super('/translations');
  }

  getAll() {
    return Promise.resolve({
      errors: {},
      success: true,
      response: Translations,
    });
  }
}

export default new TranslationService();
